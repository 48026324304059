
import { Button, Checkbox, Input, InputPassword, Link } from 'components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useFastRegistrationQuery, useLoginQuery } from 'lib/auth/useAuthorizationQuery';
import { PiWarningCircleLight } from "react-icons/pi"
import { CiUser } from "react-icons/ci";
import { RiLockPasswordFill } from "react-icons/ri";

import styles from './FormLogin.module.scss'
import { useState } from 'react';

interface LoginFormInputs {
    email: string;
    password: string;
}

export const FormLogin: React.FC = () => {
    const {
        mutate: sendLoginForm,
    } = useLoginQuery();

    const {
        mutate: fastRegistration,
    } = useFastRegistrationQuery();

    const { control, handleSubmit, watch, formState: { errors } } = useForm<LoginFormInputs>();

    const email = watch("email", "");
    const [checked, setChecked] = useState(false);

    const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
        if (!data.email || !data.password) return;
        sendLoginForm({ email: data.email, password: data.password });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <h1>Авторизация</h1>
                </div>
                <div className={styles.enter}>
                    <h4>Еще нет аккаунта?</h4>&nbsp;
                    <Link to={"/registration"}>
                        <h4>Создать</h4>
                    </Link>
                </div>
                <div className={styles.notif}>
                    <div className={styles.warn}>
                        <PiWarningCircleLight size={17} />
                    </div>
                    <div className={styles.block}>
                        <h6>Проект по обновлению находится в процессе, и мы постепенно&nbsp;включаем новые модули и компоненты. Информация о текущем статусе будет актуализироваться по мере завершения работы.</h6>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <div className={styles.inputs}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Input
                                    icon={<CiUser />}
                                    placeholder="Телефон, логин или почта"
                                    type={email.includes("@") ? "email" : "text"}
                                    fullWidth
                                    autoFocus={!email}
                                    {...field}
                                    error={errors.email && errors.email.message}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <InputPassword
                                    icon={<RiLockPasswordFill />}
                                    fullWidth
                                    placeholder="Пароль"
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.options}>
                        <Checkbox onChange={(event) => setChecked(event.currentTarget.checked)} checked={checked} label={"Запомнить"} />
                        <Link to={"/registration"}>
                            <h5>Забыл пароль?</h5>
                        </Link>
                    </div>
                    <div className={styles.login}>
                        <Button type="submit" fullWidth>Войти</Button>
                        <Button type="button" color='black' fullWidth onClick={fastRegistration}>Быстрая регистрация</Button>
                    </div>
                    <div className={styles.message}>
                        <h5>Система сгенерирует для вас логин и пароль, которые вы сможете изменить позже.</h5>
                    </div>
                </form>
            </div>
        </div>
    )
}