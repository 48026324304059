
import { Button, Checkbox, Input, InputPassword, Link, TelegramSVG } from 'components';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useSignupQuery } from 'lib/auth/useAuthorizationQuery';
import { PiWarningCircleLight } from "react-icons/pi"
import { CiUser } from "react-icons/ci";
import { RiLockPasswordFill } from "react-icons/ri";

import styles from './FormRegistration.module.scss'
import { useState } from 'react';

interface RegistrationFormInputs {
    email: string;
    password: string;
}

export const FormRegistration: React.FC = () => {
    const {
        mutate: sendSignupForm,
    } = useSignupQuery();
    const { control, handleSubmit, watch, formState: { errors } } = useForm<RegistrationFormInputs>();

    const email = watch("email", "");
    const [checkedUserEvent, setCheckedUserEvent] = useState(false);
    const [checkedUSerDate, setCheckedUSerDate] = useState(false);

    const onSubmit: SubmitHandler<RegistrationFormInputs> = (data) => {
        if (!data.email || !data.password) return;
        sendSignupForm({ email: data.email, password: data.password });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <h1>Создать аккаунт</h1>
                </div>
                <div className={styles.enter}>
                    <h4>Уже есть аккаунт?</h4>&nbsp;
                    <Link to={"/login"}>
                        <h4>Войти</h4>
                    </Link>
                </div>
                <div className={styles.notif}>
                    <div className={styles.warn}>
                        <PiWarningCircleLight size={17} />
                    </div>
                    <div className={styles.block}>
                        <h6>Проект по обновлению находится в процессе, и мы постепенно&nbsp;включаем новые модули и компоненты. Информация о текущем статусе будет актуализироваться по мере завершения работы.</h6>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
                    <div className={styles.inputs}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Input
                                    icon={<CiUser />}
                                    placeholder="Телефон, логин или почта"
                                    type={email.includes("@") ? "email" : "text"}
                                    fullWidth
                                    autoFocus={!email}
                                    {...field}
                                    error={errors.email && errors.email.message}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <InputPassword
                                    icon={<RiLockPasswordFill />}
                                    fullWidth
                                    placeholder="Пароль"
                                    {...field}
                                />
                            )}
                        />
                    </div>
                    <div className={styles.options}>
                        <Checkbox
                            onChange={(event) => setCheckedUserEvent(event.currentTarget.checked)}
                            checked={checkedUserEvent}
                            label={"Пользовательское соглашение"}
                        />
                        <Checkbox
                            onChange={(event) => setCheckedUSerDate(event.currentTarget.checked)}
                            checked={checkedUSerDate}
                            label={"Политика обработки персональных данных"}
                        />
                    </div>
                    <div className={styles.login}>
                        <Button type="submit" fullWidth>Создать аккаунт</Button>
                    </div>
                </form>
                <div className={styles.message}>
                    <h5>
                        При создании аккаунта ты соглашаешься с&nbsp;<Link to="">условиями использования</Link>
                    </h5>
                </div>
                <div className={styles.tech}>
                    <Link to="" type="empty">
                        <div className={styles.linkTech}>
                            <div className={styles.svgTelegramm}>
                                <TelegramSVG />
                            </div>
                            <h5>
                                Техническая поддержка
                            </h5>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}