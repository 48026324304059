import { Input as Field } from '@mantine/core';
import React from 'react';
import { PasswordInput } from '@mantine/core';
import styles from './InputPassword.module.scss';
import clsx from 'clsx';
import { useDisclosure } from '@mantine/hooks';

type Props = {
    placeholder: string;
    label?: string;
    required?: boolean;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void; // Add onFocus prop
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;   // Add onBlur prop
    toggle?: () => void
    autoFocus?: boolean;
    fullWidth?: boolean;
    visible?: boolean;
    icon?: React.ReactNode;
    defaultValue?: string
};

export const InputPassword = React.forwardRef<HTMLInputElement, Props>(
    ({ label, placeholder, required = false, defaultValue = "", value, onChange, onFocus, onBlur, autoFocus, fullWidth, icon }, ref) => {

        const [visible, { toggle }] = useDisclosure(false);

        return (
            <Field.Wrapper classNames={{ label: styles.label, root: styles.inputWrapper, }} label={label} description="" error="" withAsterisk={required}>
                <PasswordInput
                    label={label}
                    defaultValue={defaultValue}
                    visible={visible}
                    onVisibilityChange={toggle}
                    leftSection={icon}
                    autoFocus={autoFocus}
                    classNames={{ innerInput: icon && styles.leftIcon, input: clsx(styles.input, fullWidth && styles.fullWidth), section: clsx(icon && styles.icon, styles.leftSection),visibilityToggle: styles.rightSection  }}
                    placeholder={placeholder}
                    ref={ref}
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
            </Field.Wrapper>
        );
    }
);

InputPassword.displayName = 'InputPassword';
