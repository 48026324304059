import { Title, Text } from '@mantine/core'
import styles from './PreviewBlock.module.scss'
import { LogoSVG } from 'components/SVG'

export const PreviewBlock: React.FC = () => {
    return (
        <>
            <div className={styles.backgroundContainer}>
                <div className={styles.logo}>
                    <LogoSVG />
                </div>
                <div className={styles.fullWidthImage}></div>
                <div className={styles.gradient}>
                </div>
                <div className={styles.centeredImage}>
                    <img src="images/wallet.png" alt="Centered" />
                    <div className={styles.title}>
                        <Title size={30}>
                            <span className={styles.gradientText}>
                                Надёжный кошелёк&nbsp;
                            </span>
                            с функциями обмена и вывода криптовалют
                        </Title>
                    </div>
                    <div className={styles.options}>
                        <Text size={"lg"}>
                            Сохраняйте криптовалюту, обменивайте её, получайте прибыль и выводите средства быстро и выгодно
                        </Text>
                    </div>
                </div>
            </div>
        </>
    )
}
