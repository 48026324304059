export const LogoSVG: React.FC = () => {
    return (
        <svg width="196" height="30" viewBox="0 0 196 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M39.0839 17.2727L33.6367 7.86693L36.7927 2.31454C37.6077 0.879649 39.0899 0 40.6918 0H49.0913L39.0839 17.2727Z" fill="url(#paint0_linear_20_458)" />
            <path d="M5.24476 28.1818L0.484371 20.5641C-0.157551 19.5365 -0.161789 18.2437 0.473778 17.212L10.4818 0.994433C10.8632 0.377056 11.5453 0 12.2805 0H20.6149C21.5513 0 22.1275 1.00065 21.6424 1.78376L5.24476 28.1818Z" fill="url(#paint1_linear_20_458)" />
            <path d="M57.5912 28.1818L39.999 0H50.5168C51.2571 0 51.9461 0.377056 52.328 0.992362L62.1161 16.7272C62.9332 18.0407 62.929 19.6856 62.1076 20.9929L57.5912 28.1798V28.1818Z" fill="url(#paint2_linear_20_458)" />
            <path d="M13.6367 0H22.6986C24.1706 0 25.5329 0.779331 26.2879 2.05479L37.2731 20.3755L31.558 30L13.6367 0Z" fill="url(#paint3_linear_20_458)" />
            <path d="M92.7773 5.27314C93.7783 5.27314 94.2959 5.78413 94.3341 6.80413L95.0572 24.1303H91.1398L90.5014 8.49951H90.0019L86.9163 20.2503C86.7491 21.1238 86.1933 21.5615 85.2487 21.5615H82.58C81.6354 21.5615 81.0614 21.1238 80.858 20.2503L77.7744 8.49951H77.3011L76.7171 24.1303H72.7715L73.4945 6.80413C73.5308 5.78413 74.0585 5.27314 75.0776 5.27314H79.0514C79.9597 5.27314 80.5156 5.72075 80.719 6.61202L83.1923 16.0951C83.3031 16.5328 83.3957 16.9744 83.4702 17.4201C83.5448 17.8677 83.6273 18.3173 83.72 18.7728H84.0805C84.1732 18.3173 84.2618 17.8657 84.3443 17.4201C84.4269 16.9744 84.5155 16.5229 84.6082 16.0673L87.0533 6.61202C87.2386 5.71877 87.7945 5.27314 88.7209 5.27314H92.7773Z" fill="white" />
            <path d="M100.676 5.27309C101.503 5.27309 101.916 5.67042 101.916 6.46509V7.86825C101.916 8.66292 101.503 9.06026 100.676 9.06026H98.8709C98.0432 9.06026 97.6304 8.66292 97.6304 7.86825V6.46509C97.6304 5.67042 98.0432 5.27309 98.8709 5.27309H100.676ZM101.832 11.0469V24.1302H97.6876V11.0469H101.832Z" fill="white" />
            <path d="M108.686 10.7688L109.204 13.4195L109.607 13.7705V24.1302H105.344V10.7688H108.684H108.686ZM115.629 10.4159L115.226 14.1214H114.044C113.372 14.1214 112.67 14.2116 111.942 14.392C111.211 14.5724 110.29 14.8429 109.175 15.2037L108.887 12.9588C109.885 12.1295 110.894 11.4982 111.912 11.0649C112.929 10.6316 113.919 10.4159 114.88 10.4159H115.629Z" fill="white" />
            <path d="M120.407 10.7688L120.882 13.4195L121.251 13.7705V24.1302H117.343V10.7688H120.405H120.407ZM126.772 10.4159L126.403 14.1214H125.319C124.703 14.1214 124.06 14.2116 123.392 14.392C122.723 14.5724 121.879 14.8429 120.857 15.2037L120.593 12.9588C121.507 12.1295 122.432 11.4982 123.366 11.0649C124.297 10.6316 125.205 10.4159 126.085 10.4159H126.772Z" fill="white" />
            <path d="M139.702 16.1171L139.675 18.4476L133.758 18.4761C133.274 18.4761 132.927 18.5982 132.723 18.8405C132.516 19.0847 132.413 19.4124 132.413 19.8236V20.4973C132.413 20.9654 132.537 21.3257 132.789 21.5781C133.04 21.8305 133.444 21.9567 134 21.9567C134.466 21.9567 134.972 21.8528 135.52 21.6473C136.066 21.4417 136.61 21.1506 137.148 20.7761C137.686 20.4016 138.162 19.962 138.573 19.4551V21.2524C138.411 21.5333 138.174 21.8854 137.86 22.3047C137.546 22.726 137.146 23.1372 136.663 23.5402C136.179 23.9432 135.614 24.2851 134.969 24.566C134.323 24.8469 133.586 24.9874 132.764 24.9874C131.941 24.9874 131.231 24.8367 130.584 24.5375C129.939 24.2383 129.428 23.7987 129.052 23.2166C128.675 22.6365 128.486 21.9159 128.486 21.055V19.622C128.486 18.5168 128.845 17.6558 129.562 17.0391C130.28 16.4204 131.266 16.113 132.522 16.113H139.704L139.702 16.1171ZM136.152 10.4159C137.263 10.4159 138.205 10.5706 138.977 10.88C139.747 11.1894 140.336 11.7125 140.739 12.4534C141.143 13.1922 141.343 14.2079 141.343 15.5004V24.6556H138.17L137.604 21.6513L137.335 21.23V15.4719C137.335 14.8165 137.187 14.3402 136.891 14.0389C136.594 13.7397 136.035 13.5891 135.21 13.5891C134.528 13.5891 133.678 13.6135 132.654 13.6583C131.633 13.7051 130.584 13.7662 129.508 13.8415L129.132 11.0896C129.777 10.9594 130.522 10.8474 131.364 10.7518C132.206 10.6581 133.048 10.5788 133.892 10.5136C134.735 10.4485 135.489 10.4159 136.152 10.4159Z" fill="white" />
            <path d="M150.72 10.4159C151.209 10.4159 151.746 10.4404 152.326 10.4851C152.905 10.5319 153.491 10.6154 154.081 10.7375C154.67 10.8596 155.211 11.0326 155.7 11.2564L155.264 13.8675C154.538 13.8492 153.844 13.8309 153.182 13.8105C152.519 13.7922 151.925 13.782 151.399 13.782C150.582 13.782 149.937 13.8939 149.466 14.1198C148.995 14.3437 148.654 14.7426 148.447 15.3124C148.238 15.8843 148.133 16.6739 148.133 17.6854C148.133 18.6968 148.238 19.521 148.447 20.101C148.656 20.6811 148.995 21.084 149.466 21.3079C149.937 21.5317 150.582 21.6457 151.399 21.6457C151.689 21.6457 152.061 21.6355 152.515 21.6172C152.969 21.5989 153.458 21.5745 153.984 21.548C154.511 21.5195 155.027 21.4686 155.534 21.3933L155.915 24.0329C155.189 24.3707 154.365 24.6129 153.438 24.7635C152.513 24.9141 151.596 24.9874 150.689 24.9874C149.02 24.9874 147.691 24.7493 146.703 24.271C145.715 23.7927 145.003 23.0214 144.568 21.955C144.132 20.8886 143.915 19.4661 143.915 17.6874C143.915 15.9087 144.138 14.4862 144.581 13.4198C145.025 12.3534 145.747 11.5861 146.745 11.118C147.743 10.65 149.066 10.4159 150.716 10.4159H150.72Z" fill="#B78BF7" />
            <path d="M164.499 10.4159C166.169 10.4159 167.508 10.654 168.514 11.1323C169.52 11.6105 170.242 12.3819 170.684 13.4483C171.123 14.5147 171.344 15.9474 171.344 17.7444C171.344 19.5414 171.123 20.9171 170.684 21.9835C170.242 23.0499 169.52 23.8192 168.514 24.2852C167.508 24.7533 166.171 24.9874 164.499 24.9874C162.828 24.9874 161.513 24.7533 160.498 24.2852C159.483 23.8172 158.75 23.0499 158.303 21.9835C157.854 20.9171 157.629 19.5027 157.629 17.7444C157.629 15.986 157.854 14.5147 158.303 13.4483C158.752 12.3819 159.485 11.6085 160.498 11.1323C161.513 10.654 162.845 10.4159 164.499 10.4159ZM164.499 13.4483C163.816 13.4483 163.277 13.5704 162.882 13.8125C162.488 14.0568 162.209 14.4862 162.047 15.1048C161.884 15.7235 161.804 16.6027 161.804 17.7444C161.804 18.8861 161.884 19.7388 162.047 20.3554C162.209 20.9741 162.486 21.3995 162.882 21.6335C163.277 21.8675 163.816 21.9835 164.499 21.9835C165.183 21.9835 165.72 21.8675 166.116 21.6335C166.511 21.3995 166.79 20.9741 166.952 20.3554C167.114 19.7368 167.194 18.8678 167.194 17.7444C167.194 16.621 167.114 15.7235 166.952 15.1048C166.79 14.4862 166.511 14.0568 166.116 13.8125C165.722 13.5704 165.183 13.4483 164.499 13.4483Z" fill="#B78BF7" />
            <path d="M177.817 5.27309C178.645 5.27309 179.058 5.67042 179.058 6.46509V7.86825C179.058 8.66292 178.645 9.06026 177.817 9.06026H176.013C175.185 9.06026 174.772 8.66292 174.772 7.86825V6.46509C174.772 5.67042 175.185 5.27309 176.013 5.27309H177.817ZM178.972 11.0469V24.1302H174.827V11.0469H178.972Z" fill="#B78BF7" />
            <path d="M185.646 10.7688L186.099 13.4195L186.418 13.7705V24.1302H182.486V10.7688H185.648H185.646ZM191.623 10.4159C194.103 10.4159 195.343 11.6511 195.343 14.1214V24.1302H191.411V15.3664C191.411 14.7704 191.306 14.3606 191.092 14.1352C190.88 13.9097 190.516 13.7979 190.002 13.7979C189.436 13.7979 188.85 13.9117 188.248 14.1352C187.645 14.3606 186.902 14.7077 186.016 15.1762L185.829 12.9314C186.767 12.1197 187.73 11.4982 188.712 11.0649C189.694 10.6316 190.664 10.4159 191.621 10.4159H191.623Z" fill="#B78BF7" />
            <defs>
                <linearGradient id="paint0_linear_20_458" x1="41.364" y1="3.90338e-09" x2="41.2908" y2="11.6454" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#8C64D8" />
                    <stop offset="1" stopColor="#B78BF7" />
                </linearGradient>
                <linearGradient id="paint1_linear_20_458" x1="10.9091" y1="28.1818" x2="10.9091" y2="1.27215e-07" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#6EE3FE" />
                    <stop offset="1" stopColor="#0086FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_20_458" x1="51.3627" y1="0" x2="51.3627" y2="28.1818" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#B78BF7" />
                    <stop offset="1" stopColor="#8C64D8" />
                </linearGradient>
                <linearGradient id="paint3_linear_20_458" x1="25.4549" y1="0" x2="25.4549" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C0FF" />
                    <stop offset="1" stopColor="#6EE3FE" />
                </linearGradient>
            </defs>
        </svg>


    )
}