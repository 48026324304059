import { Button } from 'components';
import styles from './Sessions.module.scss';
import { Text } from '@mantine/core'

const data1 = "KFKDMKFMLDMLMSLMSL<MS<SM>MS><>SDDJFJKNMD<M <M C< <C LDMLSM"

export const Sessions: React.FC = () => {
    return (
        <div className={styles.content}>
            <div className={styles.row}>
                <div className={styles.column}>
                    <div className={styles.settings}>
                        <h5>Клиент</h5>
                        <div className={styles.cell}>
                            <Text size='lg'>{data1}</Text>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.settings}>
                        <h5>IP</h5>
                        <div className={styles.cell}>
                            <Text size='lg'>94.158.248.389</Text>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.settings}>
                        <h5>Окончание сессии</h5>
                        <div className={styles.cell}>
                            <Text size='lg'>7 декабря 2024 г. в 18:23:23</Text>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <Button type='button' onClick={() => { }} color={'without'}>Выйти</Button>
                </div>
            </div>
        </div>
    )
}