import { RouteNames } from 'navigate/routeNames';

export const ROUTERS = [
  { name: 'Мой логин', path: RouteNames.MY_LOGIN },
  { name: 'Кошелек', path: RouteNames.WALLET },
  { name: 'Купить mirrc', path: RouteNames.BUY_MIRRC },
  { name: 'Счета', path: RouteNames.ACCOUNTS },
  { name: 'Конференция', path: RouteNames.CONFERENCE },
  { name: 'Образование', path: RouteNames.EDUCATION },
  { name: 'Обозреватель', path: RouteNames.EXPLORER },
  { name: 'Нейро-сеть', path: RouteNames.NEURAL_NETWORK },
  { name: 'Крипто-банк', path: RouteNames.CRYPTO_BANK },
  { name: 'White Paper', path: RouteNames.WHITE_PAPER },
  { name: 'P2P', path: RouteNames.P2P },
];

export const ROUTERS_PROFILE = [
  { name: 'Профиль', path: RouteNames.PROFILE },
  { name: 'Настройки', path: RouteNames.PROFILE + RouteNames.SETTINGS },
  { name: 'Безопасность', path: RouteNames.PROFILE + RouteNames.SECURITY },
];
