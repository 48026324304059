
import React, { memo, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './SideBar.module.scss';
import { ROUTERS } from 'lib/consts';
import { LogoSVG, SettingsSVG, EyeSVG, CopySVG } from 'components';
import clsx from 'clsx';
import { RouteNames } from 'navigate/routeNames';

const token = "UUID Gh6q2asdfasdfasdfasdffjdfydf";

export const SideBar: React.FC = memo(() => {
    const [copy, setCopy] = useState(false)
    const shortenString = (str: string, maxLength: number = 20) => {
        if (str.length <= maxLength) return str;

        const partLength = Math.floor((maxLength - 3) / 2);
        return `${str.slice(0, partLength)}…${str.slice(-partLength)}`;
    }

    const handleCopy = () => {
        if (!token) return
        navigator?.clipboard.writeText(token);
        setCopy(true)
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
          setCopy(false);
        }, 3000);
    
        return () => clearTimeout(timeout);
      }, [copy]);

    return (
        <div className={styles.side}>
            <NavLink to={RouteNames.PROFILE} className={styles.logo}>
                <LogoSVG />
            </NavLink>
            <div className={styles.options}>
                <div className={styles.block}>
                    <NavLink to={"/profile"} className={({ isActive }) =>
                        isActive ? ` ${styles.link} ${styles.active} ` : styles.link
                    }>
                        <SettingsSVG />
                        <h5>Профиль</h5>
                    </NavLink>
                    <div>
                        RU
                    </div>
                </div>
                <div className={clsx(styles.token, { [styles.textCopy]: copy })} onClick={handleCopy}>
                    <div className={styles.text}>
                        <h6>{shortenString(token, 15)}</h6>
                    </div>
                    <CopySVG />
                </div>
            </div>
            <div className={styles.menu}>
                {ROUTERS.map((item) => {
                    return (
                        <NavLink
                            to={item.path}
                            className={({ isActive }) =>
                                isActive ? `${styles.link} ${styles.active} ` : styles.link
                            }
                        >
                            <h5>{item.name}</h5>
                            {
                                item?.name === 'P2P' &&
                                <div className={styles.fee}>
                                    <h6>0% fee</h6>
                                </div>
                            }
                        </NavLink>
                    )
                })}
            </div>
            <div className={styles.balance}>
                <div className={styles.header}>
                    <EyeSVG /><h5>Balance</h5>
                </div>
                <div className={styles.count}>
                    <h4>{1000}&nbsp;mirrc</h4>
                </div>
            </div>
        </div >
    );
});

