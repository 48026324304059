import { PreviewBlock } from 'components';
import styles from './Login.module.scss';
import { FormLogin } from './components/FormLogin';

export const Login: React.FC = () => {
    return (
        <div className={styles.wrapper}>
            <PreviewBlock />
            <FormLogin />
        </div>
    );
};
