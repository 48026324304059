export enum RouteNames {
  PROFILE = '/profile',
  MY_LOGIN = '/my-login',
  WALLET = '/wallet',
  BUY_MIRRC = '/buy-mirrc',
  ACCOUNTS = '/accounts',
  CONFERENCE = '/conference',
  EDUCATION = '/education',
  EXPLORER = '/explorer',
  NEURAL_NETWORK = '/neural-network',
  CRYPTO_BANK = '/crypto-bank',
  WHITE_PAPER = '/white-paper',
  REGISTRATION = '/registration',
  LOGIN = '/login',
  P2P = '/p2p',
  NOMATCH = '*',
  ERROR = '/error',
  START = '/',
  SETTINGS = '/settings',
  SECURITY = '/security',
}
