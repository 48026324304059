import { Block, Button } from 'components';
import styles from './Profile.module.scss';
import img from 'assets/images/user.png';
import { FaChevronRight } from "react-icons/fa";
import clsx from 'clsx';


const browser = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36";

export const Profile: React.FC = () => {
    return <div className={styles.content}>
        <Block>
            <div className={styles.row}>
                <div className={styles.options}>
                    <img src={img} />
                    <div className={styles.text}>
                        <h5>Верифицируйте ваш e-mail</h5>
                        <h5>Подтвердите e-mail чтобы не потерять доступ к аккаунту</h5>
                    </div>
                </div>
                <Button onClick={() => { }} type='button'>Подтвердить email</Button>
            </div>
        </Block>
        <Block color='dark'>
            <div className={clsx(styles.row, styles.security)}>
                <div className={styles.column}>
                    <div className={styles.head}>
                        <h6>Верификация личности</h6>
                    </div>
                    <div className={styles.verified}>
                        <div className={styles.color}></div>
                        <h5>Не верифицирован</h5>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.head}>
                        <h6>Безопасность&nbsp;<FaChevronRight size={9} /></h6>
                    </div>
                    <div className={styles.status}>
                        <h5>Низкая</h5>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.head}>
                        <h6>Последний вход&nbsp;<FaChevronRight size={9} /></h6>
                    </div>
                    <div className={styles.browser}>
                        <h5>{browser}</h5>
                    </div>
                </div>

            </div>
        </Block>
    </div>
}

