export const EyeSVG: React.FC = () => {
    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.00004C1 11.3245 4.93556 14.5145 9.25 14.5145C13.5644 14.5145 17.5 11.3245 17.5 8.00004C17.5 4.6756 13.54 1.4856 9.25 1.4856C4.96 1.4856 1 4.6756 1 8.00004Z" stroke="url(#paint0_linear_54_1252)" stroke-linecap="round" strokeLinejoin="round" />
            <path d="M12.0732 8.00009C12.0732 6.44787 10.8143 5.17676 9.24985 5.17676C7.6854 5.17676 6.42651 6.43565 6.42651 8.00009C6.42651 9.56454 7.6854 10.8234 9.24985 10.8234C10.8143 10.8234 12.0732 9.56454 12.0732 8.00009Z" stroke="url(#paint1_linear_54_1252)" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_54_1252" x1="1" y1="8.00004" x2="17.5" y2="8.00004" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C0FF" />
                    <stop offset="0.481592" stopColor="#A28AF8" />
                    <stop offset="1" stopColor="#F5F5F5" />
                </linearGradient>
                <linearGradient id="paint1_linear_54_1252" x1="6.42651" y1="8.00009" x2="12.0732" y2="8.00009" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00C0FF" />
                    <stop offset="0.481592" stopColor="#A28AF8" />
                    <stop offset="1" stopColor="#F5F5F5" />
                </linearGradient>
            </defs>
        </svg>

    )
}