import React from 'react';
import styles from './Registration.module.scss';
import { FormRegistration } from './components';
import { PreviewBlock } from 'components';

export const Registration: React.FC = () => {

    return (<div className={styles.wrapper}>
        <PreviewBlock />
        <FormRegistration />
    </div>)
}
