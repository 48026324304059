
import { Block, Button, Switch } from 'components'
import styles from './Settings.module.scss'
import { Text } from '@mantine/core'

export const Settings: React.FC = () => {
    return (
        <Block color='dark'>
            <div className={styles.wrapper}>
                <div className={styles.settings}>
                    <div>
                        <Text size='lg' fw={"bold"}>Уведомления на почте</Text>
                        <h5>Будем отправлять письма на mail•••@•••l.com</h5>
                    </div>
                    <div>
                        <Button type='button' onClick={() => { }}>Отключить все</Button>
                    </div>
                </div>
                <div className={styles.settings}>
                    <div>
                        <Text size='lg'>Финансовые операции</Text>
                        <h5>Выплаты, начисления, переводы и пополнения</h5>
                    </div>
                    <Switch />
                </div>
                <div className={styles.settings}>
                    <div>
                        <Text size='lg'>Реферальная программа</Text>
                        <h5>Вознаграждения</h5>
                    </div>
                    <Switch />
                </div>
                <div className={styles.settings}>
                    <div>
                        <Text size='lg'>Новости</Text>
                        <h5>Новые функции и обновления</h5>
                    </div>
                    <Switch />
                </div>
                <div className={styles.settings}>
                    <div>
                        <Text size='lg'>Уведомления в Telegram</Text>
                        <h5>Будем отправлять уведомления Telegram бота @mirrc_bot</h5>
                    </div>
                    <Button type='button' onClick={() => { }}>Привязать Telegram</Button>
                </div>
                <div className={styles.settings}>
                    <div>
                        <Text size='lg'>Промоакции</Text>
                        <h5>Скидки и бонусы</h5>
                    </div>
                    <Switch />
                </div>
            </div>
        </Block>
    )
}