import { Link as RouteLink } from 'react-router-dom';
import styles from './Link.module.scss';

type Props = {
    to: string
    type?: "navigate" | "empty"
    children?: React.ReactNode;
}

export const Link: React.FC<Props> = ({ to, children, type="navigate" }) => {
    return (
        <span className={styles[type]}>
            <RouteLink to={to}>
                {children}
            </RouteLink>
        </span>
    )
}