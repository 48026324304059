import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import styles from './ProfileLayout.module.scss';

import { useAppSelector } from 'lib/useAppSelect';
import { SideBar, Header, ErrorFallback, Menu } from 'components';
import { useEffect } from 'react';

type ProfileLayoutT = {
  children?: React.ReactNode;
};

export const ProfileLayout: React.FC<ProfileLayoutT> = ({ children }) => {
  const { isMobile } = useAppSelector((state) => state.isMobile);

  const useViewportHeightFix = () => {
    useEffect(() => {
      const updateVh = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      };

      updateVh();
      window.addEventListener('resize', updateVh);

      return () => window.removeEventListener('resize', updateVh);
    }, []);
  };

  useViewportHeightFix();

  return (
    <div className={styles.layout}>
        <SideBar />
        <div className={styles.wrapper}>
          <Header />
          <div className={styles.page}>
            {children}
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Outlet />
            </ErrorBoundary>
          </div>
        </div>
      {/* {isMobile && <Menu />} */}
    </div>
  );
}
