import React, { useState } from 'react';
import { Switch as MantineSwitch } from '@mantine/core';
import styles from './Switch.module.scss';

type Props = {
  label?: string;
};

export const Switch: React.FC<Props> = ({ label = "" }) => {
  const [checked, setChecked] = useState(false);

  return (
    <MantineSwitch
      checked={checked}
      onChange={(event) => setChecked(event.currentTarget.checked)}
      label={label}
      classNames={{
        root: styles.root,
        track: styles.track,
        thumb: styles.thumb,
        input: styles.switch,
      }}
    />
  );
};
