

import { ChangeEvent } from 'react';
import { Checkbox as MantineCheckbox } from '@mantine/core';
import styles from './Checkbox.module.scss';
import clsx from 'clsx';

type Props = {
    checked: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    label?: string
}

export const Checkbox: React.FC<Props> = ({ label = "", checked, onChange }) => {
    return (
        <MantineCheckbox
            label={label}
            classNames={{
                body: styles.body,
                input: clsx(styles.input, { [styles.checked]: checked }), 
                label: clsx(styles.label, { [styles.labelChecked]: checked })
            }}
            checked={checked}
            onChange={onChange ? onChange : undefined}
        />
    )
}