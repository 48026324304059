import {
  StartPage,
  RegistrationPage,
  ProfilePage,
  NoMatchPage,
  LoginPage,
  ErrorPage,
  WalletPage,
  MyLoginPage,
  BuyMirrcPage,
  AccountsPage,
  ConferencePage,
  ExplorerPage,
  EducationPage,
  NeuralNetworkPage,
  CryptoBankPage,
  WhitePaperPage,
  PiTwoPiPage,
  SecurityPage,
  SettingsPage,
} from 'pages';
import { RouteNames } from './routeNames';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { ProfileLayout } from 'layouts/ProfileLayout';
import { useAppSelector } from 'lib/useAppSelect';
import { PrimaryLayout } from 'layouts/PrimaryLayout';

type PrivateRouteProps = {
  children: ReactElement;
};

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isLogin } = useAppSelector((state) => state.authState);
  const location = useLocation();
  const authPath = [RouteNames.LOGIN, RouteNames.REGISTRATION];
  const isExcludePath = authPath.includes(location.pathname as RouteNames);

  if (isLogin && isExcludePath) {
    return <Navigate to={RouteNames.PROFILE} />;
  } else if (!isLogin && !isExcludePath) {
    return <Navigate to={RouteNames.LOGIN} />;
  }

  return <PrimaryLayout>{children}</PrimaryLayout>;
};

type PublicRouteProps = {
  children: ReactElement;
};

export const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isLogin } = useAppSelector((state) => state.authState);

  if (isLogin) {
    return <Navigate to={RouteNames.PROFILE} />;
  }
  return children;
};

export const Routers = () => {
  const routes = useRoutes([
    {
      path: RouteNames.START,
      element: (
        <PrivateRoute>
          <ProfileLayout />
        </PrivateRoute>
      ),
      children: [
        { path: RouteNames.START, element: <NoMatchPage /> },
        { path: RouteNames.PROFILE, element: <ProfilePage /> },
        { path: RouteNames.PROFILE + RouteNames.SETTINGS, element: <SettingsPage /> },
        { path: RouteNames.PROFILE + RouteNames.SECURITY, element: <SecurityPage /> },
        { path: RouteNames.MY_LOGIN, element: <MyLoginPage /> },
        { path: RouteNames.WALLET, element: <WalletPage /> },
        { path: RouteNames.BUY_MIRRC, element: <BuyMirrcPage /> },
        { path: RouteNames.ACCOUNTS, element: <AccountsPage /> },
        { path: RouteNames.CONFERENCE, element: <ConferencePage /> },
        { path: RouteNames.EXPLORER, element: <ExplorerPage /> },
        { path: RouteNames.EDUCATION, element: <EducationPage /> },
        { path: RouteNames.NEURAL_NETWORK, element: <NeuralNetworkPage /> },
        { path: RouteNames.CRYPTO_BANK, element: <CryptoBankPage /> },
        { path: RouteNames.WHITE_PAPER, element: <WhitePaperPage /> },
        { path: RouteNames.P2P, element: <PiTwoPiPage /> },
        { path: RouteNames.ERROR, element: <ErrorPage /> },
        { path: RouteNames.NOMATCH, element: <NoMatchPage /> },
      ],
    },
    {
      path: RouteNames.LOGIN, element:
        <PublicRoute>
          <LoginPage />
        </PublicRoute>
    },
    {
      path: RouteNames.REGISTRATION, element:
        <PublicRoute>
          <RegistrationPage />
        </PublicRoute>
    },
    { path: RouteNames.ERROR, element: <ErrorPage /> },
    { path: RouteNames.NOMATCH, element: <NoMatchPage /> },
  ]);

  return routes;
};
