
import { Block, Button, Switch } from 'components'
import styles from './Security.module.scss'
import { Text } from '@mantine/core'
import { Sessions } from './components'

export const Security: React.FC = () => {
    return (
        <>
            <Block color='dark'>
                <div className={styles.wrapper}>
                    <div className={styles.header}>
                        <Text size='lg' fw={"bold"}>Твои данные</Text>
                    </div>
                    <div className={styles.settings}>
                        <div>
                            <Text size='lg'>Почта</Text>
                            <h5>Для авторизации, смены пароля и системных писем</h5>
                        </div>
                        <Button type='button' className={styles.button} onClick={() => { }}>Подтвердить</Button>
                    </div>
                    <div className={styles.settings}>
                        <div>
                            <Text size='lg'>Телефон</Text>
                            <h5>Для 2FA и проведения сделок</h5>
                        </div>
                        <Button type='button' className={styles.button} onClick={() => { }}>Добавить</Button>
                    </div>
                    <div className={styles.settings}>
                        <div>
                            <Text size='lg'>Пароль</Text>
                            <h5>Используется для авторизации</h5>
                        </div>
                        <Button type='button' className={styles.button} onClick={() => { }}>Изменить</Button>
                    </div>
                    <div className={styles.settings}>
                        <div>
                            <Text size='lg'>Telegram</Text>
                            <h5>Для авторизации без пароля</h5>
                        </div>
                        <Button type='button' className={styles.button} onClick={() => { }}>Привязать</Button>
                    </div>
                </div>
            </Block>
            <Block color='dark'>
                <div className={styles.wrapper}>
                    <div className={styles.settings}>
                        <div>
                            <Text size='lg' fw={"bold"}>2FA-защита</Text>
                            <h5>Будешь вводить код при авторизации, выводе и совершении Р2Р сделок</h5>
                        </div>
                        <></>
                    </div>
                    <div className={styles.settings}>
                        <div>
                            <Text size='lg'>По телефону</Text>
                            <h5>Код в смс на номер телефона</h5>
                        </div>
                        <Switch />
                    </div>
                </div>
            </Block>
            <Block color='dark'>
                <div className={styles.wrapper}>
                    <div className={styles.settings}>
                        <div>
                            <Text size='lg' fw={"bold"}>Активные сессии</Text>
                        </div>
                        <Button type='button' onClick={() => { }}>Выйти со всех устройств</Button>
                    </div>
                </div>
                <Sessions/>
                <Sessions/>
                <div className={styles.all}>
                <Button type='button' onClick={() => { }}>Показать еще</Button>
            </div>
            </Block>
        </>
    )
}