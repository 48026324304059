import { useMutation, useQuery } from 'react-query';
import { ActivationQueryT, LoginQueryT, SignupQueryT } from 'types/auth';
import { useAppDispatch } from 'lib/useAppDispatch';
import authService from 'services/auth.service';
import { removeToken, setToken } from 'services';
import { toggleAuthState } from 'store/authStateSlice';
import { useNavigate } from 'react-router-dom';
import { RouteNames } from 'navigate/routeNames';
import toast from 'react-hot-toast';

export const useLoginQuery = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(
    async (data: LoginQueryT) => await authService.login(data),
    {
      retry: false,
      onSuccess: async (data) => {
          setToken(data.access_token, data.refresh_token);
          dispatch(toggleAuthState({ isLogin: true }));
          navigate(RouteNames.PROFILE);
        
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.detail || 'An unexpected error occurred.';
        toast.error(errorMessage);
        dispatch(toggleAuthState({ isLogin: false }));
      },
    }
  );
};

export const useFastRegistrationQuery = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return useMutation(async () => authService.fastRegistration(), {
    retry: false,
    onSuccess: async (data) => {
      setToken(data.jwt_tokens.access_token, data.jwt_tokens.refresh_token);
      dispatch(toggleAuthState({ isLogin: true }));
      navigate(RouteNames.PROFILE);
    },
    onError: async () => {
      dispatch(toggleAuthState({ isLogin: false }));
      navigate(RouteNames.LOGIN);
    },
  });
};

export const useSignupQuery = () => {
  return useMutation(
    async (data: SignupQueryT) => await authService.signup(data)
  );
};

export const useLogoutQuery = () => {
  const dispatch = useAppDispatch();

  return useMutation(async () => authService.logout(), {
    onSuccess() {
      removeToken();
      dispatch(toggleAuthState({ isLogin: false }));
    },
  });
};
