import { Input as Field } from '@mantine/core';
import React from 'react';
import styles from './Input.module.scss';
import clsx from 'clsx';

type Props = {
    placeholder: string;
    label?: string;
    required?: boolean;
    type?: "password" | "text" | "email" | "number";
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void; // Add onFocus prop
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;   // Add onBlur prop
    autoFocus?: boolean;
    fullWidth?: boolean;
    icon?: React.ReactNode;
    error?: string;
};

export const Input = React.forwardRef<HTMLInputElement, Props>(
    ({ label, placeholder, type = "text", required = false, value, onChange, onFocus, onBlur, autoFocus, fullWidth, icon, error }, ref) => {
        return (
            <>
                <Field.Wrapper classNames={{ label: styles.label, root: styles.inputWrapper, }} label={label} description="" error="" withAsterisk={required}>
                    <Field
                        leftSection={icon}
                        autoFocus={autoFocus}
                        type={type}
                        classNames={{ input: clsx(styles.input, icon && styles.leftIcon, fullWidth && styles.fullWidth), section: clsx(icon && styles.icon) }}
                        placeholder={placeholder}
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </Field.Wrapper>
                {!!error && <p className={styles.errors}>{error}</p>}
            </>
        );
    }
);

Input.displayName = 'Input';
