import { Button as MantineButton } from '@mantine/core';
import styles from './Button.module.scss';
import clsx from 'clsx';

type Props = {
    onClick?: () => void;
    children?: React.ReactNode;
    disabled?: boolean;
    type?: "button" | "submit" | "reset";
    color?: "primal" | "black" | "without"
    fullWidth?: boolean;
    className?: string
}

export const Button: React.FC<Props> = ({ onClick, children, type = "button", disabled, color = "primal", fullWidth, className }) => {
    return (
        <MantineButton
            classNames={{ root: clsx(styles.button, { [styles.fullWidth]: fullWidth }, styles[color], className), }}
            disabled={disabled}
            onClick={type === "button" && onClick ? onClick : undefined}
            type={type}
        >
            {children}
        </MantineButton>
    )
}
